import * as React from "react"
import { graphql } from "gatsby"

// components
import Layout from '../components/layout'
import { Trans } from 'react-i18next'

// seo
const seo = {
  title: 'seo.careers.title',
  description: 'seo.careers.description'
}

const CarreirasPage = () => {
  const vagas = [
    {
      title: 'Product Owner',
      location: 'São Paulo - SP',
      url: 'https://vericode.breezy.hr/p/785d6a1e8152-170-product-owner'
    },
    {
      title: 'Analista de Testes Não Funcionais Pleno / Sênior',
      location: 'São Paulo - SP',
      url: 'https://vericode.breezy.hr/p/36aa19df2d4c-148-analista-de-testes-nao-funcionais-pleno-senior'
    },
    {
      title: 'DevOps',
      location: 'São Paulo - SP',
      url: 'https://vericode.breezy.hr/p/7a173b1f75b9-153-devops'
    },
    {
      title: 'Analista de Automação de Testes Web+ Mobile',
      location: 'São Paulo - SP',
      url: 'https://vericode.breezy.hr/p/843103c28337-161-analista-de-automacao-de-testes-web-mobile'
    }
  ]

  return (
    <>
      <Layout seo={seo}>
        <section className="pageHeader pageHeader--full pageHeader--imageOffset">
          <div className="container">
            <h1><Trans i18nKey={seo.title} /></h1>
            <h2 className="title title--display">
              <Trans i18nKey='hero.title' />
            </h2>

            <article className="article">
              <p className="lead">
                <Trans i18nKey='hero.lead.primary' />
              </p>

              <p className="lead">
                <Trans i18nKey='hero.lead.secondary' />
              </p>
            </article>
          </div>

          <div className="imageOffset">
            <img alt="Vericoders" className="image image--rounded image--shadow" src="https://images.unsplash.com/photo-1507209696998-3c532be9b2b5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1490&q=80" />
          </div>
        </section>


        <section className="section">
          <div className="container grid md:grid-cols-2 lg:grid-cols-3 gap-lg">
            <div>
              <h3><Trans i18nKey='grid.first.title' /></h3>
              <p><Trans i18nKey='grid.first.description' /></p>
            </div>

            <div>
              <h3><Trans i18nKey='grid.second.title' /></h3>
              <p><Trans i18nKey='grid.second.description' /></p>
            </div>

            <div>
              <h3><Trans i18nKey='grid.third.title' /></h3>
              <p><Trans i18nKey='grid.third.description' /></p>
            </div>

            <div>
              <h3><Trans i18nKey='grid.fourth.title' /></h3>
              <p><Trans i18nKey='grid.fourth.description' /></p>
            </div>

            <div>
              <h3><Trans i18nKey='grid.fifth.title' /></h3>
              <p><Trans i18nKey='grid.fifth.description' /></p>
            </div>

            <div>
              <h3><Trans i18nKey='grid.sixth.title' /></h3>
              <p><Trans i18nKey='grid.sixth.description' /></p>
            </div>
          </div>
        </section>

        <hr className="divider" />

        <section className="section">
          <div className="container">
            <h2 className="title title--display"><Trans i18nKey='section.title' /></h2>

            <hr className="space" />

            <h2 className="mb-0"><Trans i18nKey='section.subtitle' /></h2>
            <hr className="divider" />
            <div className="grid mt-md mb-lg md:grid-cols-2 lg:grid-cols-3 gap-x-lg gap-y-md">
              {vagas.map((vaga, index) => {
                return (
                  <div key={index}>
                    <h3 className="mb-0 text-secondary">
                      <a rel="noreferrer" target="_blank" href={vaga.url}>
                        <Trans i18nKey={vaga.title} />
                      </a>
                    </h3>
                    <Trans i18nKey={vaga.location} />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default CarreirasPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["carreiras", "layout", "header", "footer"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;